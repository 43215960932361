<template>
  <div style="line-height: 40px; width: 100%; text-align: center">
    <Row v-if="nzPostSuggests && nzPostSuggests.length > 0" class="nzpost-suggest-panel">
      <div class="nzpost-suggest" :class="{ active: idx == nzPostSuggestActive }"
        @click="handleNzPostDetail(nzPostSuggest.uniqueId, idx)" v-for="(nzPostSuggest, idx) in nzPostSuggests"
        :key="nzPostSuggest.uniqueId">
        {{ nzPostSuggest.fullAddress }}
      </div>
    </Row>
    <Row>
      <FormItem ref="suggestionContainer" style="width: 100%">
        <Select show-search ref="suggestionInput" filterable v-model="selectedAddressFull" @on-select="selectOption"
          :remote-method="doSearch">
          <template slot="placeholder">
            在这里查询并选择地址, 符合结果的 NZPost 地址将会显示在上方
          </template>
          <Option v-for="(s, idx) in queryResult" :key="idx" :value="idx">
            {{ s.address.formattedAddress }}
          </Option>
        </Select>
      </FormItem>
    </Row>
    <Row>
      <div class="map-container" style="min-height: 350px; width: 100%;">
        <div ref="mapPlaceHolder" style="min-height: 350px;"></div>
      </div>
    </Row>
  </div>
</template>

<script>
import BingMapInitalizer from "./BingMapInitalizer.js"

import { suggestNzPost, getDetailNzPost } from "@/api/nzpost"

const defaultOptions = {}

let Microsoft = null
let _theMap = null
let _suggestionManager = null
let _searchManager = null

export default {
  name: "BingMap",
  props: {
    credentials: {
      type: String,
      default: null
    },
    options: {
      type: Object,
      default() {
        return defaultOptions
      }
    },
    center: {
      type: Object,
      default: null
    },
    zoom: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      // selectedAddressIndex: null,
      selectedAddress: {},
      selectedAddressFull: undefined,
      queryResult: [],
      nzPostSuggests: [],
      nzPostSuggestActive: -1
    }
  },
  watch: {
    center: {
      deep: true,
      handler(newCenter) {
        if (newCenter && newCenter.latitude && newCenter.longitude && _theMap) {
          _theMap.setView({ center: { latitude: newCenter.latitude, longitude: newCenter.longitude } })
        }
      }
    },
    selectedAddressFull (newValue, oldValue) {
      console.log("selectedAddressFull", newValue, oldValue)
    }
  },
  mounted() {
    BingMapInitalizer.init(this.credentials).then((ms) => {
      Microsoft = ms
      this.setupTheMap(ms)
    })
  },
  methods: {
    setupTheMap(theMS) {
      const realOptions = Object.assign(
        {
          credentials: this.credentials
        },
        this.options
      )
      if (this.center && this.center.latitude && this.center.longitude) {
        realOptions.center = new theMS.Maps.Location(this.center.latitude, this.center.longitude)
      }
      if (this.zoom) {
        realOptions.zoom = this.zoom
      }
      this.$nextTick(() => {
        _theMap = new theMS.Maps.Map(this.$refs["mapPlaceHolder"], realOptions)
        Microsoft.Maps.Events.addHandler(_theMap, "click", this.handleMapClick)
      })
    },
    async doSearch(value) {
      console.log("doSearch", value)
      const [suggestionResult, searchResult] = await Promise.all([
        new Promise(async (resolve) => {
          const suggestionManager = await this.getSuggestionManager()
          suggestionManager.getSuggestions(value.trim(), (suggestions) => {
            resolve(suggestions)
          })
        }),
        new Promise(async (resolve) => {
          let v = value.trim()
          if (v.length < 5) {
            resolve([])
          }
          if (!v.endsWith("New Zealand") && v.length < 8) {
            v = v + ", New Zealand"
          }
          const searchManager = await this.getSearchManager()
          searchManager.geocode({
            where: v,
            callback: (res) => {
              if (!res.results) {
                resolve([])
                return
              }
              resolve(res.results)
            },
            errorCallback: (req) => {
              resolve([])
            }
          })
        })
      ])
      const queryResult = [...searchResult, ...suggestionResult]
      _theMap.entities.clear()
      this.queryResult = queryResult
    },
    async selectSuggestion(value = null) {
      console.log("selectSuggestion", this.queryResult, value, typeof value === "number")
      if (value === null) {
        return
      }
      let theAddress
      if (typeof value === "number") {
        theAddress = this.queryResult[value]
        this.selectedAddress = theAddress
        this.selectedAddressFull = theAddress.address.formattedAddress
        console.log("formattedAddress", this.selectedAddressFull)
      } else {
        theAddress = this.selectedAddress
      }

      let nzPostKeywords = ""
      if (this.selectedAddress.address.addressLine) {
        nzPostKeywords = this.selectedAddress.address.addressLine
      }
      if (this.selectedAddress.address.locality) {
        if (nzPostKeywords) {
          nzPostKeywords += ", " + this.selectedAddress.address.locality
        } else {
          nzPostKeywords = this.selectedAddress.address.locality
        }
      }
      if (!nzPostKeywords) {
        console.log("skip the nzpost query cause no keywords")
        return
      }

      console.log("keywords", nzPostKeywords)

      this.nzPostSuggestActive = -1
      try {
        this.nzPostSuggests = await suggestNzPost(nzPostKeywords)
      } catch (e) {
        this.$Message.error(e.message)
      }
    },
    async getSuggestionManager() {
      try {
        if (_suggestionManager) {
          return _suggestionManager
        }
        return await new Promise((resolve, reject) => {
          Microsoft.Maps.loadModule("Microsoft.Maps.AutoSuggest", {
            callback() {
              _suggestionManager = new Microsoft.Maps.AutosuggestManager({
                map: _theMap,
                countryCode: "NZ",
                maxResults: 5
              })
              resolve(_suggestionManager)
            },
            errorCallback(msg) {
              reject(new Error(msg))
            }
          })
        })
      } catch (e) {
        console.error(e)
        throw e
      }
    },
    async getSearchManager() {
      try {
        if (_searchManager) {
          return _searchManager
        }
        return await new Promise((resolve, reject) => {
          Microsoft.Maps.loadModule("Microsoft.Maps.Search", {
            callback() {
              _searchManager = new Microsoft.Maps.Search.SearchManager(_theMap)
              resolve(_searchManager)
            },
            errorCallback(msg) {
              reject(new Error(msg))
            }
          })
        })
      } catch (e) {
        console.error(e)
        throw e
      }
    },
    async handleMapClick(event) {
      const searchRequest = {
        location: event.location,
        callback: async (placeResult) => {
          console.log("placeResult: ", placeResult)
          const country = placeResult.address.countryRegion
          if (country !== "New Zealand") {
            this.$notification.error({ message: this.$t("map.error.invalidCountry") })
            return
          }
          const pin = new Microsoft.Maps.Pushpin(placeResult.location, {
            title: placeResult.title ?? placeResult.address.addressLine,
            draggable: false,
            color: "gray"
          })
          _theMap.entities.clear()
          _theMap.entities.push(pin)
          _theMap.setView({ center: placeResult.location })
          // Microsoft.Maps.Events.addHandler(pin, "click", this.handlePinClick)
          this.selectedAddress = placeResult
          this.selectedAddressFull = placeResult.address.formattedAddress
          console.log("handleMapClick", this.selectedAddressFull)
          await this.selectSuggestion()
        },
        errorCallback: (r) => { },
        includeNeighborhood: true
      }
      const searchManager = await this.getSearchManager()
      searchManager.reverseGeocode(searchRequest)
    },
    handlePinClick(event, updatedLocation) {
      this.$emit("locationUpdated", updatedLocation)
    },
    async handleNzPostDetail(uniqueId, suggestIdx) {
      console.log("handleNzPostDetail", uniqueId)
      this.nzPostSuggestActive = suggestIdx
      const [detail] = await getDetailNzPost(uniqueId)
      let location
      if (detail && detail.nzgd2kCoord && detail.nzgd2kCoord.coordinates && detail.nzgd2kCoord.coordinates.length === 2) {
        location = {
          latitude: detail.nzgd2kCoord.coordinates[1],
          longitude: detail.nzgd2kCoord.coordinates[0]
        }
      }
      if (!location) {
        return
      }

      const fullAddress = [...Array(5).keys()]
        .map((i) => "addressLine" + (i + 1))
        .reduce((acc, curKey, idx) => {
          if (idx === 0) {
            return detail[curKey]
          } else if (detail[curKey]) {
            return acc + ", " + detail[curKey]
          }
          return acc
        }, "")
      const street = detail.roadName + (detail.roadTypeName ? " " + detail.roadTypeName : "")
      const streetNumber = detail.streetNumber
      const district = detail.suburb
      const city = detail.cityTown
      const postalCode = detail.postcode
      const country = "NZ"

      const updatedLocation = { fullAddress, street, streetNumber, district, ...location, city, postalCode, country }
      this.$emit("locationUpdated", updatedLocation)

      const pin = new Microsoft.Maps.Pushpin(location, {
        title: detail.addressLine1,
        draggable: false,
        color: "red"
      })
      _theMap.entities.clear()
      _theMap.entities.push(pin)
      _theMap.setView({ center: location })
      Microsoft.Maps.Events.addHandler(pin, "click", (ev) => this.handlePinClick(ev, updatedLocation))
    },
    selectOption(e) {
      console.log(e)
      this.selectSuggestion(e.value)
    },
    initMap() {
      this.selectedAddress = {}
      // this.selectedAddressFull = undefined,
      this.queryResult = []
      this.nzPostSuggests = [],
      this.nzPostSuggestActive = -1
    }
  }
}
</script>

<style scoped>
.map-container {
  height: 100%;
}

.nzpost-suggest-panel {
  overflow-x: scroll;
  overflow-y: auto;
  display: flex;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.nzpost-suggest {
  display: list-item;
  flex: none;
  width: 140px;
  padding: 5px;
  border: 1px dotted;
  margin: 0 10px 5px 0;
  list-style: square inside;
  cursor: pointer;
}

.nzpost-suggest:hover {
  border: 1px dotted red;
  color: red;
}

div.active {
  border: 1px solid red;
  color: red;
}
</style>
