<template>
  <ContentWrapper :links="topLinks">
    <Form :model="form" style="padding-left: 80px;padding-right: 100px;" :label-width="150">
      <FormItem label="名称">
        <Input v-model="form.name" placeholder="请输入标题" :disabled="disabled" />
      </FormItem>
      <FormItem label="店铺Logo">
        <div v-if="!(logoImage && logoImage.url)">
          <Upload multiple :headers="{ 'X-Auth': token }" action="/backstage/media/uploadImages" name="images"
            :format="['jpg', 'jpeg', 'png']" :on-success="logoUploaded"
            :show-upload-list="false">
            <Button icon="ios-cloud-upload-outline">上传logo</Button>
          </Upload>
        </div>
        <div v-else>
          <span class="imgWrapper">
            <img v-real-img="logoImage.url" style="width: 80px; height: 80px" />
            <Button class="imgCloseR" shape="circle" icon="md-close" @click="removeUploadLogo(logoImage.path)"></Button>
          </span>
        </div>
      </FormItem>
      <FormItem label="店铺图片">
        <div v-if="uploadedImages.length === 0">暂未上传图片</div>
        <div v-else>
          <draggable class="uploadedImages" element="ul" :list="uploadedImages"
            :options="{ group: 'title', animation: 150 }" :no-transition-on-drag="true">
            <span class="imgWrapper" v-for="(i, index) in uploadedImages" :key="i.path">
              <img v-real-img="i.url" style="width: 80px; height: 80px" />
              <span class="imgFrist" v-if="index === 0">首图</span>
              <Button class="imgClose" shape="circle" icon="md-close" @click="removeUploadImage(i.path)"></Button>
            </span>
          </draggable>
        </div>
      </FormItem>
      <FormItem label="">
        <Upload multiple :headers="{ 'X-Auth': token }" action="/backstage/media/uploadImages" name="images"
          :format="['jpg', 'jpeg', 'png']" :max-size="5120" :before-upload="filesReadyToUpload"
          :on-exceeded-size="exceededSize" :on-success="filesUploaded" :show-upload-list="false">
          <Button icon="ios-cloud-upload-outline">上传</Button>
        </Upload>
      </FormItem>
      <FormItem label="简介">
        <Input type="textarea" :rows="5" v-model="form.desc" placeholder="请输入简介" :disabled="disabled" />
      </FormItem>
      <FormItem label="店长">
        <Button @click="showUserModel" :disabled="disabled || !!alias">{{ userInfo.nickname ? userInfo.nickname : "选择用户" }}</Button>
      </FormItem>
      <FormItem label="业态">
        <Select v-model="form.businessTypeList" multiple :disabled="disabled">
          <Option v-for="item in filteredList" :value="item.alias" :key="item.alias">
            {{ item.name }}
          </Option>
        </Select>
      </FormItem>
      <FormItem label="导购食堂链接">
        <Input v-model="externalUrl" placeholder="请输入链接" :disabled="disabled" />
      </FormItem>
      <FormItem label="文字描述" v-if="externalUrl.length > 0">
        <Input type="textarea" :rows="5" v-model="externalDesc" placeholder="请输入文字描述" :disabled="disabled" />
      </FormItem>
      <FormItem label="图片" v-if="externalUrl.length > 0">
        <div v-if="uploadedImages.length === 0">暂未上传图片</div>
        <div v-else>
          <draggable class="uploadedImages" element="ul" :list="externalMedias"
            :options="{ group: 'title', animation: externalMedias }" :no-transition-on-drag="true">
            <span class="imgWrapper" v-for="i in externalMedias" :key="i.path">
              <img v-real-img="i.url" style="width: 80px; height: 80px" />
              <Button class="imgClose" shape="circle" icon="md-close" @click="removeExternalMediasImage(i.path)"></Button>
            </span>
          </draggable>
        </div>
        <Upload multiple :headers="{ 'X-Auth': token }" action="/backstage/media/uploadImages" name="images"
          :format="['jpg', 'jpeg', 'png']" :max-size="5120" :before-upload="externalMediasReadyToUpload"
          :on-exceeded-size="exceededSize" :on-success="externalMediasUploaded" :show-upload-list="false">
          <Button icon="ios-cloud-upload-outline">上传</Button>
        </Upload>
      </FormItem>
      <FormItem label="是否开启电商功能">
        <Checkbox v-model="form.openShop" :disabled="disabled"></Checkbox>
      </FormItem>
      <Row>
        <Col span="11">
        <FormItem label="联系人">
          <Input v-model="form.contact" :disabled="disabled" />
        </FormItem>
        </Col>
        <Col span="2">
        </Col>
        <Col span="11">
        <FormItem label="联系电话">
          <Input v-model="form.phone" placeholder="请输入联系电话" :disabled="disabled" />
        </FormItem>
        </Col>
      </Row>
      <FormItem label="完整地址">
        <Input v-model="form.address.fullAddress" disabled />
      </FormItem>
      <FormItem>
        <BingMap ref="bingMap" credentials="AgzeobkGvmpdZTFuGa7_6gkaHH7CXHKsFiTQlBvi55x-QLZLh1rSjhd1Da9bfPhD" :options="{
          disableBirdseye: true,
          disableStreetside: true
        }" :center="mapCenter" :zoom="14" style="width: 100%; height: 100%" @locationUpdated="mapLocationUpdated">
        </BingMap>
      </FormItem>
      <Row>
        <Col span="11">
        <FormItem label="门牌号">
          <Input v-model="form.address.streetNumber" disabled />
        </FormItem>
        </Col>
        <Col span="2">
        </Col>
        <Col span="11">
        <FormItem label="街道">
          <Input v-model="form.address.street" disabled />
        </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span="11">
        <FormItem label="街区">
          <Input v-model="form.address.district" disabled />
        </FormItem>
        </Col>
        <Col span="1">
        </Col>
        <Col span="6">
        <FormItem label="城市">
          <Input v-model="form.address.city" disabled />
        </FormItem>
        </Col>
        <Col span="1">
        </Col>
        <Col span="5">
        <FormItem label="邮编">
          <Input v-model="form.address.postalCode" disabled />
        </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span="5">
          <FormItem label="外部系统">
            <Select v-model="system">
              <Option value="0">无</Option>
              <Option value="1">AI System</Option>
            </Select>
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span="11">
          <FormItem label="AI Account Identity" v-if="isShowIdentity">
            <Input v-model="accountIdentity" placeholder="Enter AI Account Identity..."></Input>
          </FormItem>
        </Col>
        <Col span="2">
        </Col>
        <Col span="11">
          <FormItem label="AI Store Identity" :label-width="150" v-if="isShowIdentity">
            <Input v-model="storeIdentity" placeholder="Enter AI Store Identity..."></Input>
          </FormItem>
        </Col>
      </Row>
      <FormItem>
        <Button size="large" type="primary" @click="publish" :disabled="disabled">发布</Button>
      </FormItem>
    </Form>
    <Modal v-model="showUserSelector" width="80%" title="选择用户">
      <UserList @selectionChanged="selectorUser" :withoutStore="true" v-if="showUserSelector" />
    </Modal>
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "@/components/ContentWrapper.vue"
import BingMap from "@/components/BingMap/BingMap.vue"
import draggable from "vuedraggable"
import UserList from "@/components/user/UserList"
import { apiUtil } from "@/util"
import { getDetail, queryBusinesstype, updateStore, createStore } from "@/api"

const theSkyTower = { lat: -36.8483, lng: 174.7622 }

const defaultAddressInit = () => {
  return {
    latitude: theSkyTower.lat,
    longitude: theSkyTower.lng,
  }
}

const maxFiles = 9

export default {
  name: "StorePublishPage",
  components: { BingMap, draggable, UserList, ContentWrapper },
  data() {
    return {
      topLinks: [{ title: "发布店铺", link: this.$route.path }],
      uploadedImages: [],
      logoImage: {},
      token: sessionStorage.getItem("token"),
      form: this.initForm(),
      userInfo: {},
      address: defaultAddressInit(),
      mapCenter: defaultAddressInit(),
      suggestionResult: [],
      nzpost: true,
      showUserSelector: false,
      parentList: [],
      alias: this.$route.query.alias,
      disabled: this.$route.query.disabled ? true : false,
      accountIdentity: "",
      storeIdentity: "",
      system: "0",
      externalUrl: "",
      externalDesc: "",
      externalMedias: []
    }
  },
  computed: {
    filteredList() {
      return this.parentList
    },
    isShowIdentity() {
      if (this.system != 0) {
        return true
      }
      return false
    }
  },
  watch: {
    "address.latitude"(newLat) {
      this.mapCenter.latitude = newLat
    },
    "address.longitude"(newLng) {
      this.mapCenter.longitude = newLng
    },
    alias() {
      this.reload()
    },
    "userInfo.alias"(alias) {
      this.form.sellerAlias = alias
    }
  },
  async mounted() {
    await this.reload()
    console.log("this.$route.query.alias", this.$route.query.alias)
    this.process(await queryBusinesstype())
  },
  methods: {
    process(cate) {
      if (cate.children && !cate.isLeaf) {
        cate.children = cate.children.map(item => this.process(item))
      }
      cate.expand = true
      cate.title = cate.name
      this.parentList.push({ name: cate.name, alias: cate.alias })
    },
    async reload() {
      if (this.alias) {
        this.form.storeAlias = this.alias
        const detail = await getDetail(this.alias)
        this.updateForm(detail)
        if (detail.external && detail.external.url) {
          this.externalUrl = detail.external.url
          this.externalDesc = detail.external.desc
          this.externalMedias = detail.external.medias ? detail.external.medias : []
        }
      } else {
        this.init()
      }
    },
    filesReadyToUpload() {
      if (1 + this.uploadedImages.length > maxFiles) {
        this.$Message.error(`单个商品最多可以上传${maxFiles}张图片`)
        return false
      }
    },
    filesUploaded(response) {
      if (this.uploadedImages.length + 1 > maxFiles) {
        this.$Message.error(`单个商品最多可以上传${maxFiles}张图片, 多余的图片将被忽略`)
        return
      }
      this.uploadedImages = this.uploadedImages.concat(response.data)
    },
    removeUploadImage(key) {
      this.uploadedImages = this.uploadedImages.filter(image => image.path != key)
    },
    externalMediasReadyToUpload() {
      if (1 + this.externalMedias.length > 3) {
        this.$Message.error("单个商品最多可以上传3张图片")
        return false
      }
    },
    externalMediasUploaded(response) {
      console.log("externalMediasUploaded", response)
      if (this.externalMedias.length + 1 > 3) {
        this.$Message.error("单个商品最多可以上传3张图片, 多余的图片将被忽略")
        return
      }
      this.externalMedias = this.externalMedias.concat(response.data)
    },
    removeExternalMediasImage(key) {
      this.externalMedias = this.externalMedias.filter(image => image.path != key)
    },
    logoUploaded(response) {
      this.logoImage = response.data[0]
    },
    exceededSize() {
      this.$Message.error("图片大小超过5m")
    },
    removeUploadLogo() {
      this.logoImage = {}
    },
    async handleMapAdded() {
      this.addressEditorMode = "no"
      this.$notification.success({ message: this.$t("map.message.added") })
    },
    async handleMapUpdated() {
      this.addressEditorMode = "no"
      this.$notification.success({ message: this.$t("map.message.updated") })
    },
    async handleMapCancel() {
      this.address = defaultAddressInit()
      this.addressEditorMode = "no"
    },
    mapLocationUpdated(newLocation) {
      console.log(newLocation)
      this.form.address = newLocation
    },
    showUserModel() {
      this.showUserSelector = true
    },
    hideUserModel() {
      this.showUserSelector = false
    },
    selectorUser(e) {
      this.userInfo = e
      this.hideUserModel()
    },
    init() {
      this.uploadedImages = []
      this.form = this.initForm()
      this.userInfo = {}
      this.address = defaultAddressInit()
      this.mapCenter = defaultAddressInit()
      this.suggestionResult = []
      this.$nextTick(() => {
        this.$refs.bingMap.initMap()
      })
    },
    initForm() {
      return {
        storeAlias: this.alias,
        name: "",
        medias: [],
        desc: "",
        sellerAlias: "",
        openShop: false,
        businessTypeList: [],
        contact: "",
        phone: "",
        logo: this.logoImage,
        address: {
        },
        storeExternal: null
      }
    },
    updateForm(value) {
      value.businessTypeList = value.businessTypeList.map(item => item.alias)
      this.form = value
      this.userInfo = value.seller
      this.form.sellerAlias = value.seller.alias
      this.uploadedImages = value.medias
      this.logoImage = value.logo
      if (value.storeExternal) {
        this.system = value.storeExternal.system.toString()
        this.accountIdentity = value.storeExternal.accountIdentity
        this.storeIdentity = value.storeExternal.storeIdentity
      }
    },
    async publish() {
      apiUtil.call(
        this,
        async () => {
          const medias = this.uploadedImages.map((item, index) => {
            if (index === 0) {
              return { path: item.path, sortOrder: 100 - index, isPrimary: true }
            } else {
              return { path: item.path, sortOrder: 100 - index }
            }
          })
          const formData = Object.assign({}, this.form, { "medias": medias }, { logo: this.logoImage })
          if (this.system == "0") {
            formData.storeExternal = null
          } else {
            if (!this.accountIdentity) {
              this.$Message.error("AI Account Identity为必填项")
              return
            }

            if (!this.storeIdentity) {
              this.$Message.error("AI Store Identity为必填项")
              return
            }
            formData.storeExternal = {
              system: this.system,
              accountIdentity: this.accountIdentity,
              storeIdentity: this.storeIdentity
            }
          }

          if (this.externalUrl.length > 0) {
            if (this.externalDesc.length <= 0) {
              this.$Message.error("请填写导购食堂内容")
              return
            }
            if (this.externalMedias.length !== 3) {
              this.$Message.error("请上传导购食堂图片（三张）")
              return
            }
            const external = {
              url: this.externalUrl,
              desc: this.externalDesc,
              medias: this.externalMedias
            }

            formData.external = external
          } else {
            formData.external = null
          }

          if (formData.storeAlias) {
            await updateStore(formData)
            this.$Message.success("编辑成功")
          } else {
            await createStore(formData)
            this.$Message.success("新建成功")
          }
          this.$router.back(-1)
        },
        () => {
        },
      )
    },
  },
}
</script>

<style>

</style>
    