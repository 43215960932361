const uuid = Math.floor(Math.random() * 1024)

const callbackName = "bingMapCallback" + uuid

let loaded = false

export default {
  init(bingUesrKey, market = "en-NZ", lang = "en") {
    const bingMapUrl = `//www.bing.com/api/maps/mapcontrol?setMkt=${market}&setLang=${lang}&callback=${callbackName}&key=${bingUesrKey}`
    return new Promise((resolve, reject) => {
      if (loaded && window.Microsoft) {
        console.log("bing map already loaded")
        resolve(window.Microsoft)
        return
      }

      const loader = document.createElement("script")
      loader.type = "text/javascript"
      loader.src = bingMapUrl
      loader.async = true
      loader.defer = true
      loader.onload = () => {
        console.log("bing script loaded")
      }
      loader.onerror = () => {
        loaded = false
        reject(new Error("Map load failed"))
      }

      window[callbackName] = () => {
        console.log("bing map loaded")
        loaded = true
        resolve(window.Microsoft)
      }

      document.body.appendChild(loader)
    })
  }
}
